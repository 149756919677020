import { FC } from "react";
import { Form, useLocation } from "@remix-run/react";
import { Box } from "@mui/material";

type RemoveFromWishlistFormProps = {
  children: React.ReactElement;
  productId: number;
};

const RemoveFromWishlistForm: FC<RemoveFromWishlistFormProps> = ({
  children,
  productId,
}) => {
  let location = useLocation();
  return (
    <Box sx={{ width: "100%" }}>
      <Form replace method="post" action="/wishlist">
        <input type="hidden" name="_action" value="remove-from-wishlist" />
        <input
          key={location.pathname + location.search}
          defaultValue={location.pathname + location.search}
          type="hidden"
          name="redirect"
        />
        <input
          key={productId}
          defaultValue={productId}
          type="hidden"
          name="product-id"
        />
        {children}
      </Form>
    </Box>
  );
};

export default RemoveFromWishlistForm;
