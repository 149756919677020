import { Box } from "@mui/material";
import { useFetcher, useLocation } from "@remix-run/react";
import { FC } from "react";

type AddToCartFormProps = {
  children: React.ReactElement;
  productId: string;
  quantity: number;
  parentSku?: string;
  isFull?: boolean;
};

const AddToCartForm: FC<AddToCartFormProps> = ({
  children,
  productId,
  quantity,
  parentSku,
  isFull = true,
}) => {
  const location = useLocation();
  const fetcher = useFetcher();

  return (
    <Box sx={{ width: isFull ? "100%" : "unset" }}>
      <fetcher.Form
        method="post"
        action="/cart"
        id="add-to-cart-form"
        preventScrollReset={true}
      >
        <input type="hidden" name="_action" value="add-to-cart" />
        <input
          key={location.pathname + location.search}
          defaultValue={location.pathname + location.search}
          type="hidden"
          name="redirect"
        />
        <input
          key={productId}
          defaultValue={productId}
          type="hidden"
          name="product-id"
        />

        {parentSku && (
          <input
            key={`parent-${parentSku}`}
            defaultValue={parentSku}
            type="hidden"
            name="parent-sku"
          />
        )}

        {/* <input
          key={""}
          defaultValue={""}
          type="hidden"
          name="variant-id"
        /> */}

        <input
          key={quantity}
          defaultValue={quantity}
          type="hidden"
          name="quantity"
        />
        {children}
      </fetcher.Form>
    </Box>
  );
};

export default AddToCartForm;
